.App {
    text-align: center;
  }
  
  .App_logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App_logo {
      animation: App-logo-spin infinite 21s linear;
    }
  }
  
  .Inside_boarder {
    padding-left: 3vh;
    padding-right: 3vh;
    background-color: var(--main-white-color);
  }

  .App_header {
    background-color: #282c34;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #c3baba;
    
    border-radius: 20px;
  }
  
  .App_link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  