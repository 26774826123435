/*the height for the navbar on mobile is 14vh and on desktop 10vh*/

.Button_container{
  display:inline;
  padding: 100px;
}

.menu_icon{
  width: 4vh;
  display: inline;
}

.menu_icon_button_lite{
  margin-left: 85vw;
  margin-top: 4vh;
  background-color: var(--main-white-color);
  border: 1px;
  border-radius: 10%;
}

.menu_icon_button_dark{
  margin-left: 85vw;
  margin-top: 4vh;
  background-color: var(--main-blue-color);;
  border:0px;
  border-radius: 10%;
}

.overtop{
    z-index: 101;
    background-color: var(--main-blue-color);
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0px;
    left: 100vw;
}


  @media only screen and (max-width: 600px) {
    .hide_mobile {
      display: none !important;
    }



    .NavBar_Container{
      position: fixed;
      top: 0;
      z-index: 100;
      width: 100%;
      
      height: 14vh;
      background-color: var(--main-white-color);;
      max-width: 100vw;
    }

    .page_list_item_container{
      width: 100vw;
      list-style-type: none;
      padding-left: 0;
    }

    .title_wrapper{
      margin-top: 10px;
      margin-left: 27.5vw;
      margin-right: 27.5vw;
      text-align: center;
      width: 45vw;
      border-radius: 10%;
      position: absolute;
    }

    .title_text_wrapper{
      padding: 0;
      margin: 0;
    }

    .title_text{
      padding: 0;
      margin-bottom: 0;
      font-family: Impact;
      font-size: 4vh;
    }

    .linkItem{
      color: var(--main-white-color);
    }

  }
  
  @media not all and (max-width: 600px) {
    .hide_desktop {
      display: none !important;
    }


    .logo{
      width: 5%;
      margin-right: 1%;
      padding-bottom: 1%;
      display: inline-block;
    }
    
    .page_link_container{
      display: inline-block;
    }

    .page_list_item_container{
      display: inline-block;
    }

    .title_wrapper{
      display: inline-block;
    }

    .title_text_wrapper{
      display: inline-block;
      margin-right: 1vw;
    }

    .title_text{
      font-family: Impact;
      font-size: 2.5vw;
      display: inline-block;
    }

    .NavBar_Container {
      padding-left: 3vw;
      padding-right: 3vw;

      background-color: var(--main-white-color);;
      max-width: 100vw;
      height: 10vh;
    }

    .linkItem{
      font-size: 1.5vw;
      margin: 1vw;
      color: var(--main-black-color) !important;
    }

    .selected{
      text-decoration: underline;
    }

    .NavBar_item_container{
      padding-top: 1.5vh;
    }
  }